import { GENDERS } from '@/constants/generals'

export default {
  // page 1 Jenis Kelamin
  gender: (value) => {
    return GENDERS.find((el) => el.name == value)?.label || value
  },
  
  // page 4 Jenis olahraga
  sportType: (value) => {
    return value.map((item) => `- ${item.type}${item.duration ? ', ' + item.duration : ''}`)
      .join('\n')
  },
}